// ? These components function with alpine + tailwind UI components
import Swiper from 'swiper'
import {Autoplay, EffectCreative, FreeMode, Mousewheel, Navigation, Pagination} from 'swiper/modules'

window.obaia.Components.navigationScreen = (options) => {
    return {
        showScreen: options.showScreen ?? false,
        targetUrl: '',
        timer: 0,
        maxTime: 3000, // in ms
        externalReferrer: options.externalReferrer ?? false,
        message: '',
        onload: options.onload ?? false,
        init() {
            if (this.externalReferrer) {
                this.showScreen = true
                this.message = 'We verwijzen je nu door naar Grensland.nl'
                this.updateProgressBar()
                setTimeout(() => {
                    this.showScreen = false
                    this.externalReferrer = false
                }, this.maxTime)
            }
            console.log(this.onload)
            if (this.onload) {
                this.showScreen = true
                this.message = 'We verwijzen je nu door naar Grensland.nl'
                this.updateProgressBar()
                setTimeout(() => {
                    this.showScreen = false
                    this.externalReferrer = false
                }, this.maxTime)
            }
        },
        updateProgressBar() {
            this.$refs.progressBar.style.transition = 'none'
            this.$refs.progressBar.style.width = '0%'
            setTimeout(() => {
                this.$refs.progressBar.style.transition = 'width ' + this.maxTime + 'ms linear'
                this.$refs.progressBar.style.width = '100%'
            }, 10)
        },
        // handleClick(event, url) {
        //     event.preventDefault()
        //     this.showScreen = true
        //     this.targetUrl = url
        //     this.timer = 0
        //     this.message = 'We verwijzen je nu door naar Grensland IJs en Patisserie'
        //     this.updateProgressBar()
        //     setTimeout(() => {
        //         window.open(this.targetUrl, '_blank')
        //         this.showScreen = false
        //     }, this.maxTime)
        // },
        handleClick(event, url) {
            event.preventDefault()
            this.showScreen = true
            this.targetUrl = url
            this.timer = 0
            this.message = 'We verwijzen je nu door naar Grensland IJs en Patisserie'
            this.updateProgressBar()
            this.externalReferrer = false

            // Open a blank page in a new tab immediately
            // const newTab = window.open('about:blank', '_blank')

            // Get the current domain
            const currentDomain = window.location.origin

            // Construct the URL for the new tab
            const newTabUrl = `${currentDomain}?ref=int`

            // Open the new tab with the constructed URL
            const newTab = window.open(newTabUrl, '_blank')

            setTimeout(() => {
                // Close the waiting screen
                this.showScreen = false
                // Redirect the user by changing the location of the new tab
                if (newTab) {
                    newTab.location.href = this.targetUrl
                }
            }, this.maxTime)
        }


    }
}

window.obaia.Components.miniParallax = (options) => {
    // let ticking = false
    // let lastScroll = 0
    return {
        parallaxValue: '0px',
        threshold: options?.threshold ?? 4,
        direction: options?.direction ?? 'down',
        ticking: false,
        lastScroll: 0,
        status: options?.direction ?? false,
        elementTop: 0, // Store the element's initial top offset
        init() {
            // Assuming this.$el is the element you're applying the parallax effect to
            this.elementTop = this.$el.getBoundingClientRect().top + window.scrollY;
        },
        handleScroll() {
            this.lastScroll = window.scrollY;
            // Only request an animation frame if not already requested
            if (!this.ticking && this.status) {
                window.requestAnimationFrame(() => {
                    this.update.call(this)
                })
                this.ticking = true
            }
        },
        update() {
            // Update the value with the last known scroll position
            const viewportHeight = window.innerHeight
            const elementHeight = this.$el.offsetHeight

            // Determine the adjusted scroll position, taking the element's height into account
            let adjustedScroll = this.lastScroll + viewportHeight - this.elementTop

            // Ensure the adjusted scroll is between 0 and the sum of the viewport height and element height
            // This prevents the parallax effect from inverting midway through the element
            adjustedScroll = Math.max(0, Math.min(adjustedScroll, viewportHeight + elementHeight))

            // Update the parallax value based on the adjusted scroll position and the threshold
            let newParallaxValue = (this.direction === 'up' ? '-' : '') + Math.abs(Math.round(adjustedScroll / this.threshold)) + 'px'
            this.$el.style = "--parallax:" + newParallaxValue
            // gsap.to(
            //     this.$el,
            //     {
            //         "--parallax": newParallaxValue,
            //         // ease: "slow(2, 2, false)",
            //         ease: "sine.out",
            //     }
            // )
            this.ticking = false
        }
    }
}
window.obaia.Components.card3DEffect = () => {
    return {
        // Current transform states
        currentX: 0,
        currentY: 0,
        // Target transform states
        targetX: 0,
        targetY: 0,
        // Tweening speed
        tweenSpeed: 0.1,
        animationFrameId: null,
        boundTweenTransform: null,
        isAnimating: false,
        init() {
            this.reset(true)
            // requestAnimationFrame(this.tweenTransform.bind(this))
            this.boundTweenTransform = this.tweenTransform.bind(this)
            this.animationFrameId = requestAnimationFrame(this.boundTweenTransform)
        },
        applyTransform() {
            this.$refs.card.style.transform = `rotateY(${this.currentY}deg) rotateX(${this.currentX}deg) translateZ(-50px)`
            this.$refs.text.style.transform = `rotateY(${this.currentY}deg) rotateX(${this.currentX}deg) translateZ(0px)`
        },
        mouseMove(event) {
            const cardRect = this.$refs.card.getBoundingClientRect()
            const mouseX = event.clientX - cardRect.left
            const mouseY = event.clientY - cardRect.top

            this.targetX = ((mouseY - (cardRect.height / 2)) / cardRect.height * 30).toFixed(2)
            this.targetY = ((mouseX - (cardRect.width / 2)) / cardRect.width * 30).toFixed(2)

            if (!this.isAnimating) {
                this.startAnimation()
            }
        },
        tweenTransform() {
            if (Math.abs(this.currentX - this.targetX) > 0.01 || Math.abs(this.currentY - this.targetY) > 0.01) {
                this.currentX += (this.targetX - this.currentX) * this.tweenSpeed
                this.currentY += (this.targetY - this.currentY) * this.tweenSpeed
                this.applyTransform()
                this.animationFrameId = requestAnimationFrame(this.boundTweenTransform)
            } else {
                // When the target is reached, stop the animation
                this.isAnimating = false
            }
        },
        startAnimation() {
            if (!this.isAnimating) {
                this.isAnimating = true
                this.animationFrameId = requestAnimationFrame(this.boundTweenTransform)
            }
        },
        reset(shouldApplyTransformImmediately = false) {
            this.targetX = 0
            this.targetY = 0
            if (shouldApplyTransformImmediately) {
                this.currentX = 0
                this.currentY = 0
                this.applyTransform()
            }
        },
        mouseLeave() {
            // Smoothly transition back instead of stopping abruptly
            this.reset() // No immediate transform apply, let tween handle it
        },
        mouseEnter() {
            // Ensure animation can restart on mouse enter
            this.startAnimation()
        }
    }
}

window.obaia.Components.swiper = (options) => {
    return {
        swiperInstance: null,

        goToSlide(index) {
            this.swiperInstance.slideTo(index)
        },
        init() {
            const thisElem = this.$el
            this.swiperInstance = new Swiper(thisElem, {
                modules: [Navigation, Pagination, Mousewheel, EffectCreative, Autoplay, FreeMode],
                autoplay: options.autoplay ?? false,
                speed: options.speed ?? 300,
                freeMode: options.freeMode ?? false,
                // cssMode: options.cssMode ?? false,
                grabCursor: options.grabCursor ?? false,
                loop: options.loop ?? false,
                autoHeight: options.autoHeight ?? false,
                slidesPerView: options.slidesPerView ?? 1,
                centeredSlides: options.centeredSlides ?? false,
                slidesPerGroup: options.slidesPerGroup ?? 1,
                spaceBetween: options.spaceBetween ?? 16,
                initialSlide: options.initialSlide ?? 0,
                mousewheel: {
                    forceToAxis: true,
                },
                navigation: {
                    prevEl: this.$refs.swiperPrev ?? (thisElem.closest('section')?.querySelector('.js-swiper-button-prev') ?? undefined),
                    // prevEl: this.$refs.swiperPrev ?? thisElem.closest('section').querySelector('.js-swiper-button-prev'),
                    nextEl: this.$refs.swiperNext ?? (thisElem.closest('section')?.querySelector('.js-swiper-button-next') ?? undefined),
                },
                pagination: options.pagination ? {
                    el: this.$refs.swiperPagination ?? thisElem.closest('section')?.querySelector('.js-swiper-pagination') ?? null,
                    clickable: true,
                    renderBullet: function (index, className) {
                        let names = []
                        thisElem.querySelectorAll('.swiper-slide').forEach((slide) => {
                            names.push(slide.getAttribute('data-name') || "")
                        })
                        return '<li class="cursor-pointer antialiased ' + className + '">' + names[index] + '</li>';
                    }
                } : {},
                breakpoints: {
                    ...options.breakpoints
                },
            })
        }
    }
}

window.obaia.Components.listbox = function listbox(options) {
    let modelName = options.modelName || 'selected'
    let pointer = useTrackedPointer()

    return {
        init() {
            this.optionCount = this.$refs.listbox.children.length
            this.$watch('activeIndex', (value) => {
                if (!this.open) return

                if (this.activeIndex === null) {
                    this.activeDescendant = ''
                    return
                }

                this.activeDescendant = this.$refs.listbox.children[this.activeIndex].id
            })
        },
        activeDescendant: null,
        optionCount: null,
        open: false,
        activeIndex: null,
        selectedIndex: 0,
        get active() {
            return this.items[this.activeIndex]
        },
        get [modelName]() {
            return this.items[this.selectedIndex]
        },
        choose(option) {
            this.selectedIndex = option
            this.open = false
        },
        onButtonClick() {
            if (this.open) return
            this.activeIndex = this.selectedIndex
            this.open = true
            this.$nextTick(() => {
                this.$refs.listbox.focus()
                this.$refs.listbox.children[this.activeIndex].scrollIntoView({block: 'nearest'})
            })
        },
        onOptionSelect() {
            if (this.activeIndex !== null) {
                this.selectedIndex = this.activeIndex
            }
            this.open = false
            this.$refs.button.focus()
        },
        onEscape() {
            this.open = false
            this.$refs.button.focus()
        },
        onArrowUp() {
            this.activeIndex = this.activeIndex - 1 < 0 ? this.optionCount - 1 : this.activeIndex - 1
            this.$refs.listbox.children[this.activeIndex].scrollIntoView({block: 'nearest'})
        },
        onArrowDown() {
            this.activeIndex = this.activeIndex + 1 > this.optionCount - 1 ? 0 : this.activeIndex + 1
            this.$refs.listbox.children[this.activeIndex].scrollIntoView({block: 'nearest'})
        },
        onMouseEnter(evt) {
            pointer.update(evt)
        },
        onMouseMove(evt, newIndex) {
            // Only highlight when the cursor has moved
            // Pressing arrow keys can otherwise scroll the container and override the selected item
            if (!pointer.wasMoved(evt)) return
            this.activeIndex = newIndex
        },
        onMouseLeave(evt) {
            // Only unhighlight when the cursor has moved
            // Pressing arrow keys can otherwise scroll the container and override the selected item
            if (!pointer.wasMoved(evt)) return
            this.activeIndex = null
        },
        ...options,
    }
}

window.obaia.Components.menu = function menu(options = {open: false}) {
    let pointer = useTrackedPointer()

    return {
        init() {
            this.items = Array.from(this.$el.querySelectorAll('[role="menuitem"]'))
            this.$watch('open', () => {
                if (this.open) {
                    this.activeIndex = -1
                }
            })
        },
        activeDescendant: null,
        activeIndex: null,
        items: null,
        open: options.open,
        focusButton() {
            this.$refs.button.focus()
        },
        onButtonClick() {
            this.open = !this.open
            if (this.open) {
                this.$nextTick(() => {
                    this.$refs['menu-items'].focus()
                })
            }
        },
        onButtonEnter() {
            this.open = !this.open
            if (this.open) {
                this.activeIndex = 0
                this.activeDescendant = this.items[this.activeIndex].id
                this.$nextTick(() => {
                    this.$refs['menu-items'].focus()
                })
            }
        },
        onArrowUp() {
            if (!this.open) {
                this.open = true
                this.activeIndex = this.items.length - 1
                this.activeDescendant = this.items[this.activeIndex].id

                return
            }

            if (this.activeIndex === 0) {
                return
            }

            this.activeIndex = this.activeIndex === -1 ? this.items.length - 1 : this.activeIndex - 1
            this.activeDescendant = this.items[this.activeIndex].id
        },
        onArrowDown() {
            if (!this.open) {
                this.open = true
                this.activeIndex = 0
                this.activeDescendant = this.items[this.activeIndex].id

                return
            }

            if (this.activeIndex === this.items.length - 1) {
                return
            }

            this.activeIndex = this.activeIndex + 1
            this.activeDescendant = this.items[this.activeIndex].id
        },
        onClickAway($event) {
            if (this.open) {
                const focusableSelector = [
                    '[contentEditable=true]',
                    '[tabindex]',
                    'a[href]',
                    'area[href]',
                    'button:not([disabled])',
                    'iframe',
                    'input:not([disabled])',
                    'select:not([disabled])',
                    'textarea:not([disabled])',
                ]
                    .map((selector) => `${selector}:not([tabindex='-1'])`)
                    .join(',')

                this.open = false

                if (!$event.target.closest(focusableSelector)) {
                    this.focusButton()
                }
            }
        },

        onMouseEnter(evt) {
            pointer.update(evt)
        },
        onMouseMove(evt, newIndex) {
            // Only highlight when the cursor has moved
            // Pressing arrow keys can otherwise scroll the container and override the selected item
            if (!pointer.wasMoved(evt)) return
            this.activeIndex = newIndex
        },
        onMouseLeave(evt) {
            // Only unhighlight when the cursor has moved
            // Pressing arrow keys can otherwise scroll the container and override the selected item
            if (!pointer.wasMoved(evt)) return
            this.activeIndex = -1
        },
    }
}

window.obaia.Components.popoverGroup = function popoverGroup() {
    return {
        __type: 'popoverGroup',
        init() {
            let handler = (e) => {
                if (!document.body.contains(this.$el)) {
                    window.removeEventListener('focus', handler, true)
                    return
                }
                if (e.target instanceof Element && !this.$el.contains(e.target)) {
                    window.dispatchEvent(
                        new CustomEvent('close-popover-group', {
                            detail: this.$el,
                        })
                    )
                }
            }
            window.addEventListener('focus', handler, true)
        },
    }
}

window.obaia.Components.popover = function popover({open = false, focus = false} = {}) {
    const focusableSelector = [
        '[contentEditable=true]',
        '[tabindex]',
        'a[href]',
        'area[href]',
        'button:not([disabled])',
        'iframe',
        'input:not([disabled])',
        'select:not([disabled])',
        'textarea:not([disabled])',
    ]
        .map((selector) => `${selector}:not([tabindex='-1'])`)
        .join(',')

    function focusFirst(container) {
        const focusableElements = Array.from(container.querySelectorAll(focusableSelector))

        function tryFocus(element) {
            if (element === undefined) return

            element.focus({preventScroll: true})

            if (document.activeElement !== element) {
                tryFocus(focusableElements[focusableElements.indexOf(element) + 1])
            }
        }

        tryFocus(focusableElements[0])
    }

    return {
        __type: 'popover',
        open,
        init() {
            if (focus) {
                this.$watch('open', (open) => {
                    if (open) {
                        this.$nextTick(() => {
                            focusFirst(this.$refs.panel)
                        })
                    }
                })
            }

            let handler = (e) => {
                if (!document.body.contains(this.$el)) {
                    window.removeEventListener('focus', handler, true)
                    return
                }
                let ref = focus ? this.$refs.panel : this.$el
                if (this.open && e.target instanceof Element && !ref.contains(e.target)) {
                    let node = this.$el
                    while (node.parentNode) {
                        node = node.parentNode
                        if (node.__x instanceof this.constructor) {
                            if (node.__x.$data.__type === 'popoverGroup') return
                            if (node.__x.$data.__type === 'popover') break
                        }
                    }
                    this.open = false
                }
            }

            window.addEventListener('focus', handler, true)
        },
        onEscape() {
            this.open = false
            if (this.restoreEl) {
                this.restoreEl.focus()
            }
        },
        onClosePopoverGroup(e) {
            if (e.detail.contains(this.$el)) {
                this.open = false
            }
        },
        toggle(e) {
            this.open = !this.open
            if (this.open) {
                this.restoreEl = e.currentTarget
            } else if (this.restoreEl) {
                this.restoreEl.focus()
            }
        },
    }
}

window.obaia.Components.radioGroup = function radioGroup({initialCheckedIndex = 0} = {}) {
    return {
        value: undefined,
        active: undefined,
        init() {
            let options = Array.from(this.$el.querySelectorAll('input'))

            this.value = options[initialCheckedIndex]?.value

            for (let option of options) {
                option.addEventListener('change', () => {
                    this.active = option.value
                })
                option.addEventListener('focus', () => {
                    this.active = option.value
                })
            }

            window.addEventListener(
                'focus',
                () => {
                    console.log('Focus change')
                    if (!options.includes(document.activeElement)) {
                        console.log('HIT')
                        this.active = undefined
                    }
                },
                true
            )
        },
    }
}

window.obaia.Components.tabs = function tabs() {
    return {
        selectedIndex: 0,
        onTabClick(event) {
            if (!this.$el.contains(event.detail)) return

            let tabs = Array.from(this.$el.querySelectorAll('[x-data^="Components.tab("]'))
            let panels = Array.from(this.$el.querySelectorAll('[x-data^="Components.tabPanel("]'))

            let idx = tabs.indexOf(event.detail)
            this.selectedIndex = idx

            window.dispatchEvent(
                new CustomEvent('tab-select', {
                    detail: {
                        tab: event.detail,
                        panel: panels[idx],
                    },
                })
            )
        },
        onTabKeydown(event) {
            if (!this.$el.contains(event.detail.tab)) return

            let tabs = Array.from(this.$el.querySelectorAll('[x-data^="Components.tab("]'))
            let tabIndex = tabs.indexOf(event.detail.tab)

            if (event.detail.key === 'ArrowLeft') {
                this.onTabClick({detail: tabs[(tabIndex - 1 + tabs.length) % tabs.length]})
            } else if (event.detail.key === 'ArrowRight') {
                this.onTabClick({detail: tabs[(tabIndex + 1) % tabs.length]})
            } else if (event.detail.key === 'Home' || event.detail.key === 'PageUp') {
                this.onTabClick({detail: tabs[0]})
            } else if (event.detail.key === 'End' || event.detail.key === 'PageDown') {
                this.onTabClick({detail: tabs[tabs.length - 1]})
            }
        },
    }
}

window.obaia.Components.tab = function tab(defaultIndex = 0) {
    return {
        selected: false,
        init() {
            let tabs = Array.from(
                this.$el
                    .closest('[x-data^="Components.tabs("]')
                    .querySelectorAll('[x-data^="Components.tab("]')
            )
            this.selected = tabs.indexOf(this.$el) === defaultIndex
            this.$watch('selected', (selected) => {
                if (selected) {
                    this.$el.focus()
                }
            })
        },
        onClick() {
            window.dispatchEvent(
                new CustomEvent('tab-click', {
                    detail: this.$el,
                })
            )
        },
        onKeydown(event) {
            if (['ArrowLeft', 'ArrowRight', 'Home', 'PageUp', 'End', 'PageDown'].includes(event.key)) {
                event.preventDefault()
            }

            window.dispatchEvent(
                new CustomEvent('tab-keydown', {
                    detail: {
                        tab: this.$el,
                        key: event.key,
                    },
                })
            )
        },
        onTabSelect(event) {
            this.selected = event.detail.tab === this.$el
        },
    }
}

window.obaia.Components.tabPanel = function tabPanel(defaultIndex = 0) {
    return {
        selected: false,
        init() {
            let panels = Array.from(
                this.$el
                    .closest('[x-data^="Components.tabs("]')
                    .querySelectorAll('[x-data^="Components.tabPanel("]')
            )
            this.selected = panels.indexOf(this.$el) === defaultIndex
        },
        onTabSelect(event) {
            this.selected = event.detail.panel === this.$el
        },
    }
}

function useTrackedPointer() {
    /** @type {[x: number, y: number]} */
    let lastPos = [-1, -1]

    return {
        /**
         * @param {PointerEvent} evt
         */
        wasMoved(evt) {
            let newPos = [evt.screenX, evt.screenY]

            if (lastPos[0] === newPos[0] && lastPos[1] === newPos[1]) {
                return false
            }

            lastPos = newPos
            return true
        },

        /**
         * @param {PointerEvent} evt
         */
        update(evt) {
            lastPos = [evt.screenX, evt.screenY]
        },
    }
}